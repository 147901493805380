import { BejamasMark, BejamasWordmark } from "@bejamas/ui/logo";
import { Nav } from "./site-nav";
import { FastLink } from "~/components/faster-link";
import { ProgressiveBlur } from "~/components/progressive-blur";
import { useState, useEffect } from "react";
import { useMobileNav } from "./site-nav";

const useIsScrollTop = () => {
  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => setIsTop(window.scrollY === 0);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return isTop;
};

export function SiteHeader() {
  const isTop = useIsScrollTop();
  const { closeMobileNav } = useMobileNav();

  return (
    <>
      <header className="absolute top-0 w-full">
        <div
          className="flex justify-between items-center px-6 py-6 lg:px-8"
          style={{
            "--blend-color": isTop ? "var(--foreground)" : "white",
            "--blend-color-bg": isTop
              ? "rgba(20,20,20,0.05)"
              : "rgba(255,255,255,0.05)",
            "--blend": isTop ? "normal" : "exclusion",
          }}
        >
          <div
            className="fixed top-8 z-40 text-[var(--blend-color)]"
            style={{
              mixBlendMode: "var(--blend)",
            }}
          >
            <FastLink to="/" aria-label="Bejamas" onClick={closeMobileNav}>
              <BejamasMark className="block w-7 h-auto lg:hidden" />
              <BejamasWordmark className="hidden lg:block w-[7.75rem] h-auto" />
            </FastLink>
          </div>
          <Nav />
        </div>
      </header>
      <ProgressiveBlur
        className="w-full fixed h-40 top-0 z-[21] pointer-events-none"
        blurEnd={16}
        layers={5}
        direction="to-top"
      />
      {/* <div className="fixed top-0 left-0 w-full h-40 bg-gradient-to-b to-transparent from-background" /> */}
      {/* <div className="w-full fixed h-40 top-0 z-[21] pointer-events-none">
        <div className="w-full backdrop-blur-lg header-blur absolute h-28 top-0 z-[31] pointer-events-none" />
        <div className="w-full header-blur backdrop-blur-md absolute h-40 top-0 z-[31] pointer-events-none" />
        <div className="w-full header-blur backdrop-blur-sm absolute h-40 top-0 z-[31] pointer-events-none" />
      </div> */}
    </>
  );
}
