import React, { useEffect, useRef, useState } from "react";
import { m as motion } from "framer-motion";

const CANVAS_WIDTH = 1920;
const CANVAS_HEIGHT = 1080;

const CanvasAnimation = () => {
  const ctnDom = useRef<HTMLDivElement>(null);
  const workerRef = useRef<Worker>();
  const [isReady, setIsReady] = useState(false);
  const isReadyRef = useRef(false); // This ref keeps track of the isReady state

  useEffect(() => {
    if (!ctnDom.current) {
      return;
    }

    const ctn = ctnDom.current;
    const canvas = document.createElement("canvas");
    Object.assign(canvas.style, {
      position: "absolute",
      top: "0",
      width: `${CANVAS_WIDTH}px`,
      height: `${CANVAS_HEIGHT}px`,
      backgroundColor: "transparent",
      left: "50%",
      transform: "translateX(-50%)",
    });
    ctn.appendChild(canvas);
    const offscreen = canvas.transferControlToOffscreen();

    const worker = new Worker(
      new URL("./canvas-animation.worker.js", import.meta.url),
      {
        type: "module",
      },
    );

    workerRef.current = worker;

    const handleWorkerMessage = (event: MessageEvent) => {
      if (event.data.type === "loaded") {
        if (!isReadyRef.current) {
          React.startTransition(() => {
            setIsReady(true);
          });
          isReadyRef.current = true; // Update the ref to indicate that isReady has been set
          // console.log("Worker is ready");
        }
      }
    };

    const initWorker = async () => {
      worker.postMessage(
        {
          type: "init",
          canvas: offscreen,
          width: ctn.offsetWidth,
          height: ctn.offsetHeight,
        },
        [offscreen],
      );

      worker.onmessage = handleWorkerMessage;
      worker.onerror = (error) => {
        console.error("Worker error:", error);
      };
    };

    initWorker();

    const resize = () => {
      const scale = 1;
      const width = ctn.offsetWidth * scale;
      const height = ctn.offsetHeight * scale;
      worker.postMessage({ type: "resize", width, height });
    };

    window.addEventListener("resize", resize, false);
    resize();

    return () => {
      worker.terminate();
      window.removeEventListener("resize", resize);
      ctn.removeChild(canvas);
    };
  }, []);

  return (
    <div
      style={{ position: "absolute", width: "100%" }}
      className="overflow-clip h-[1080px]"
    >
      <motion.div
        initial={{ opacity: 0, filter: "blur(20px)" }}
        animate={
          isReady
            ? { opacity: 1, filter: "blur(0px)" }
            : {
                opacity: 0,
                filter: "blur(20px)",
              }
        }
        exit={{ opacity: 0, filter: "blur(20px)" }}
        transition={{ duration: 3 }}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 20,
        }}
        className="flex justify-center items-center"
        ref={ctnDom}
      />
    </div>
  );
};

export { CanvasAnimation };
