import React, { useState, useRef, useEffect } from "react";
import { m as motion, useInView, stagger } from "framer-motion";
import { Image } from "@unpic/react";
import { cn } from "@bejamas/ui/utils";

const icons = [
  {
    icon: "https://cdn.sanity.io/images/34ent8ly/production/4c4d03481c4586759a0c7dfa4f05d497d2580ead-824x824.png",
    color: "bg-white",
  },
  {
    icon: "https://cdn.sanity.io/images/34ent8ly/production/3bd0abbbae7f870800e14056f3a6e6283e8cf1df-824x824.png",
    color: "bg-white",
  },
  {
    icon: "https://cdn.sanity.io/images/34ent8ly/production/d76a13972ab49640e660a045905c2dd564eda40a-824x824.png",
    color: "bg-[#f13e2e]",
  },
  {
    icon: "https://cdn.sanity.io/images/34ent8ly/production/6c05736fa74ad44659fddb9decfc4ed02acefd07-824x824.png",
    color: "bg-white",
  },
  {
    icon: "https://cdn.sanity.io/images/34ent8ly/production/223a29eb0698fb7fbc6d158a6f7e698d155e025f-824x824.png",
    color: "bg-black",
  },
  {
    icon: "https://cdn.sanity.io/images/34ent8ly/production/bf3240306931248d7a7f9ee94ef19604a04955a3-824x824.png",
    color: "bg-white",
  },
  {
    icon: "https://cdn.sanity.io/images/34ent8ly/production/d008f32c1c12d6207366ff5dab29f667457943ea-824x824.png",
    color: "bg-black",
  },
  {
    icon: "https://cdn.sanity.io/images/34ent8ly/production/34a22e1572bb2bc582478a9889af435f1fd9d2e6-824x824.png",
    color: "bg-white",
  },
  {
    icon: "https://cdn.sanity.io/images/34ent8ly/production/ab699de5525edf6533dca358fd04b4db4e23f1b5-824x824.png",
    color: "bg-[#4945fd]",
  },
  {
    icon: "https://cdn.sanity.io/images/34ent8ly/production/436c0b088c5629d69b965fab38989e03c48222da-824x824.png",
    color: "bg-black",
  },
  {
    icon: "https://cdn.sanity.io/images/34ent8ly/production/0f97e9e9595b807d03bb1c651aebbc5d08c37577-824x824.png",
    color: "bg-white",
  },
  {
    icon: "https://cdn.sanity.io/images/34ent8ly/production/a773f2f737daee662b8cc71ad19ebe47c344f979-824x824.png",
    color: "bg-black",
  },
  {
    icon: "https://cdn.sanity.io/images/34ent8ly/production/cbf0801a355e95d26c0bc92c5f259b7034314e66-824x824.png",
    color: "bg-gradient-to-r from-[#ff593e] to-[#ff754e]",
  },
  {
    icon: "https://cdn.sanity.io/images/34ent8ly/production/f55c9dd47f3be125064f514d35dd5f971a3c3209-824x824.png",
    color: "bg-white",
  },
];

const InteractiveCircles = () => {
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, {
    once: true,
    margin: "0px 0px -10%",
  });

  const gridSize = { rows: 3, cols: 7 };
  // const amplitude = 120; // Controls the height of the wave
  // const y = Array.from(
  //   { length: gridSize.cols },
  //   (_, i) => amplitude * Math.sin((i / (gridSize.cols - 1)) * Math.PI),
  // );

  // Option 1: Using easeInOutExpo-like curve
  const amplitude = 400; // Max height
  // const y = Array.from({ length: gridSize.cols }, (_, i) => {
  //   const x = i / (gridSize.cols - 1);
  //   return (
  //     amplitude *
  //     (x < 0.5
  //       ? Math.pow(2, 20 * x - 10) / 2
  //       : (2 - Math.pow(2, -20 * x + 10)) / 2)
  //   );
  // });

  // Option 2: Using cubic bezier-like curve

  // const y = Array.from({ length: gridSize.cols }, (_, i) => {
  //   const x = i / (gridSize.cols - 1);
  //   return amplitude * (x * x * (3 - 2 * x));
  // });
  const y = Array.from({ length: gridSize.cols }, (_, i) => {
    const x = i / (gridSize.cols - 1);
    return -(amplitude * Math.pow(2, -10 * x));
  });

  // Option 3: Using power function (closest to your example)
  // const y = Array.from({ length: gridSize.cols }, (_, i) => {
  //   const x = i / (gridSize.cols - 1);
  //   return amplitude * Math.pow(x, 0.7);
  // });

  const circles = [];
  for (let row = 0; row < gridSize.rows; row++) {
    for (let col = 0; col < gridSize.cols; col++) {
      const x = (col / (gridSize.cols - 1)) * 100; // Percentage-based positioning
      // const y = (row / (gridSize.rows - 1)) * 100; // Percentage-based positioning
      circles.push({ id: `${row}-${col}`, x, y: y[col] });
    }
  }

  const staggerDelayExit = stagger(0.0000125, {
    ease: "easeOut",
    // from: "last",
  });

  return (
    <>
      <motion.div
        className="px-5 mb-80 lg:px-8 lg:mb-0"
        initial="visible"
        // animate={isInView ? "visible" : "hidden"}
        animate="visible"
        variants={{
          visible: {
            x: 0,
            y: 0,
            opacity: 1,
            // filter: "blur(0px)",
            // scale: 1,
            transition: {
              ease: "easeInOut",
              duration: 0.4,
              // type: "spring",
              // bounce: 0,
              delay: 0.15,
            },
          },
          hidden: {
            x: 0,
            y: -10,
            opacity: 0,
            // scale: 0.98,
            // filter: "blur(10px)",
            transition: {
              ease: "easeInOut",
              duration: 0.4,
              // type: "spring",
              // bounce: 0,
              delay: 0,
            },
          },
        }}
      >
        <div className="pt-20 ml-auto md:pt-64 lg:pt-80 lg:pb-56 lg:w-5/12 text-pretty">
          {/* <p className="text-muted-foreground">Discover Modern Web</p> */}
          <p className="max-w-xl text-3xl text-balance">
            Smart marketers understand the value of craft. It's the difference
            between a website that follows trends and one that sets them.
          </p>
        </div>
      </motion.div>
      {/* <div className="container grid grid-cols-4 mt-12 mb-20 lg:hidden">
        {Array.from({ length: 8 }).map((_, i) => (
          <Image
            src="https://cdn.sanity.io/images/34ent8ly/production/699217898b9dd18697916d06c1742d34f113a997-475x510.png"
            width={475}
            height={510}
            alt="glass circle"
            key={i}
          />
        ))}
      </div> */}
      <div
        ref={containerRef}
        className="hidden grid-cols-7 justify-center -mt-12 mb-56 w-full lg:grid"
      >
        {circles.map((circle, i) => {
          return (
            <motion.div
              key={circle.id}
              animate={isInView ? "visible" : "hidden"}
              variants={{
                visible: {
                  x: 0,
                  y: circle.y,
                  // filter: "blur(0px)",
                },
                hidden: {
                  x: 0,
                  y: 0,
                  // filter: "blur(4px)",
                },
              }}
              transition={{
                duration: 0.4,
                type: "spring",
                bounce: 0,
                delay: staggerDelayExit(i, circles.length),
              }}
              className="flex relative justify-center items-center -mt-12"
            >
              <Image
                src="https://cdn.sanity.io/images/34ent8ly/production/699217898b9dd18697916d06c1742d34f113a997-475x510.png"
                width={475}
                height={510}
                alt="glass circle"
              />
              {/* <motion.div
                className={cn(
                  "flex absolute top-[34px] left-1/2 justify-center items-center w-[72px] h-[72px] bg-black rounded-full -translate-x-1/2",
                  icons[i]?.color || "bg-black",
                )}
                animate={isInView ? "visible" : "hidden"}
                variants={{
                  visible: {
                    opacity: 1,
                    scale: 1,
                    x: "-50%",
                    // filter: "blur(0px)",
                  },
                  hidden: {
                    opacity: 0,
                    scale: 0.9,
                    x: "-50%",
                    // filter: "blur(4px)",
                  },
                }}
                transition={{
                  duration: 0.4,
                  type: "spring",
                  bounce: 0,
                  delay: staggerDelayExit2(i, circles.length),
                }}
              >
                <Image
                  src={
                    icons[i]?.icon ||
                    "https://cdn.sanity.io/images/34ent8ly/production/d008f32c1c12d6207366ff5dab29f667457943ea-824x824.png"
                  }
                  alt="icon"
                  width={50}
                  height={50}
                  className="rounded-full"
                />
              </motion.div> */}
            </motion.div>
          );
        })}
      </div>
    </>
  );
};

export { InteractiveCircles };
