import { Toaster as Sonner } from "sonner";

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      theme="light"
      className="toaster group"
      toastOptions={{
        style: {
          border: "none",
          boxShadow:
            "0 30px 60px hsla(0, 0%, 9%, 0.08), 0 0 0 1px rgba(89, 89, 89, 0.06)",
        },
        classNames: {
          toast:
            "group toast rounded-xl! toolbar group-[.toaster]:bg-white group-[.toaster]:rounded-3xl font-medium group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-none",
          description: "group-[.toast]:text-muted-foreground",
          actionButton:
            "group-[.toast]:bg-primary group-[.toast]:text-primary-foreground",
          cancelButton:
            "group-[.toast]:bg-muted group-[.toast]:text-muted-foreground",
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
