import React from "react";
import { Button } from "@bejamas/ui/button";
import AlproVideo from "./video-alpro";
import AlproBgVideo from "./alpro-bg-video";
import type ReactPlayer from "react-player";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@bejamas/ui/dialog";

export function FeaturedTestimonial() {
  const [playFullVideo, setPlayFullVideo] = React.useState(false);
  const videoRef = React.useRef<ReactPlayer | null>(null);

  return (
    <Dialog>
      <section className="sm:bg-card-foreground min-h-svh px-5 md:px-[10vh] py-10 lg:py-32 flex flex-col justify-around relative">
        <blockquote className="relative z-10 max-w-xl text-2xl leading-normal sm:text-white/90 sm:mix-blend-plus-lighter md:text-3xl text-pretty">
          <p>
            Our recent codebase audit led by Bejamas was a game-changer for our
            digital platform. Their meticulous review of our stack, which
            includes Gatsby, Netlify, and Contentful, unveiled areas of
            improvement we hadn't considered.
          </p>
          <p className="mt-8">
            Their recommendations not only enhanced our code quality but also
            streamlined our deployment process. A must-have for any serious
            digital team!
          </p>
          <footer className="mt-8">
            <cite className="not-italic">
              <p className="text-xl font-medium">Ralph Urmel</p>
              <p className="text-base opacity-60">
                Senior Digital Experience Manager at Alpro
              </p>
            </cite>
          </footer>
        </blockquote>
        <DialogTrigger asChild>
          <Button
            className="sm:text-white mt-5 sm:mt-0 sm:absolute sm:left-8 sm:top-20 lg:top-auto lg:left-auto lg:right-[10vh] z-20 lg:bottom-32 bg-transparent rounded-md backdrop-blur-sm hover:bg-white/20 sm:mix-blend-plus-lighter sm:border-white/30"
            variant="bordered"
            onClick={() => {
              setPlayFullVideo(true);
              videoRef.current?.getInternalPlayer().play();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="16"
              fill="none"
              viewBox="0 0 13 16"
              className="mr-3"
              role="graphics-symbol"
            >
              <path
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.667"
                d="M1.755 1.78A.5.5 0 0 0 1 2.21v11.58a.5.5 0 0 0 .755.43l9.77-5.79a.5.5 0 0 0 0-.86z"
              />
            </svg>
            Play video
          </Button>
        </DialogTrigger>
        <div className="flex relative z-10 gap-12 mt-20 sm:text-white/60 sm:mix-blend-plus-lighter">
          <div className="max-w-xs w-full border-l sm:border-[hsla(0,_0%,_90%,_0.24)] pl-6">
            <p>Better page performance</p>
            <p className="mt-20 text-3xl sm:text-white md:text-7xl">+18%</p>
          </div>
          <div className="max-w-xs w-full border-l sm:border-[hsla(0,_0%,_90%,_0.24)] pl-6">
            <p>Better user engagement</p>
            <p className="mt-20 text-3xl sm:text-white md:text-7xl">+20%</p>
          </div>
        </div>

        <div className="hidden absolute top-0 left-0 z-0 w-1/2 h-full bg-gradient-to-r to-transparent opacity-70 sm:block from-foreground" />
        <AlproBgVideo />
        {/* <Image
      src="https://cdn.sanity.io/images/34ent8ly/production/ee1c69bb3de2dc129f587c06f9b182858b4b58dd-2914x1537.png"
      alt=""
      width={2914}
      height={1537}
      className="object-cover absolute top-0 left-0 w-full h-full -z-10"
    /> */}

        <DialogContent className="p-10 sm:max-w-7xl aspect-video">
          <div className="relative w-full aspect-video">
            <React.Suspense>
              {playFullVideo ? (
                <AlproVideo playFullVideo={playFullVideo} />
              ) : null}
            </React.Suspense>
          </div>
        </DialogContent>
      </section>
    </Dialog>
  );
}
