import { cn } from "../lib/utils";

export function BejamasWordmark({
  className,
  style,
}: {
  className?: string;
  style?: React.CSSProperties;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="409"
      height="105"
      fill="none"
      viewBox="0 0 409 105"
      className={className}
      style={style}
    >
      <path
        fill="currentColor"
        d="M114.6 61.59c-1.42 4.78-5.89 7.42-12.7 7.42-8.23 0-12.91-4.47-13.83-13.32l37.81-.1v-3.76c0-15.65-9.55-25.71-24.6-25.71S76.27 36.99 76.27 52.75s10.47 26.42 25.41 26.42c13.11 0 22.26-6.6 24.39-17.58h-11.49.02zm-13.21-25.31c7.42 0 12.2 4.57 12.2 11.48H88.38c1.22-7.52 5.69-11.48 13.01-11.48zM395.729 49.19l-9.05-2.13c-4.27-1.02-6.5-2.85-6.5-5.69 0-3.76 2.85-6 7.73-6 4.88 0 7.62 2.74 7.73 7.01h11.79c-.1-9.76-7.73-16.26-19.01-16.26-11.28 0-19.92 5.89-19.92 15.75 0 8.03 4.37 12.3 13.42 14.33l9.05 2.14c4.47 1.02 5.79 2.85 5.79 5.39 0 3.66-3.15 6-8.95 6-5.8 0-8.94-2.74-9.05-7.11h-11.79c0 9.86 7.93 16.56 20.43 16.56s21.04-6.3 21.04-16.46c0-7.73-4.27-11.59-12.71-13.52v-.01zM232.56 77.63V49.4c0-8.14 4.78-12 10.67-12s9.66 3.76 9.66 10.78v29.45h12.4V49.4c0-8.23 4.57-12.1 10.57-12.1s9.76 3.76 9.76 10.97v29.35h12.3V45.01c0-11.59-6.71-18.9-18.91-18.9-7.62 0-13.52 3.76-16.06 9.55-2.64-5.79-7.93-9.55-15.55-9.55-7.22 0-12.3 3.25-14.84 7.42l-1.02-5.9h-11.38v49.99h12.4v.01zM196.728 32.87c-3.45-4.11-9.42-6.63-16.14-6.63-14.78 0-25.51 11.12-25.51 26.44 0 15.32 10.34 26.44 25.14 26.44 6.9 0 13.01-2.75 16.51-7.24l-.01 5.76h11.24V27.65h-11.24s0 4.8.01 5.22zM181.798 69c-8.43 0-15.21-7.46-15.21-16.42s6.79-16.3 15.21-16.3c8.42 0 15.18 7.19 15.18 16.3S190.228 69 181.798 69zM347.369 71.88c0 .31-.01 4.13-.01 5.76h11.24V27.65h-11.24s0 4.8.01 5.22c-3.45-4.11-9.42-6.63-16.14-6.63-14.78 0-25.51 11.12-25.51 26.44 0 15.32 10.34 26.44 25.14 26.44 6.9 0 13.01-2.75 16.51-7.24zM332.439 69c-8.43 0-15.21-7.46-15.21-16.42s6.78-16.3 15.21-16.3 15.18 7.19 15.18 16.3S340.869 69 332.439 69zM134.78 104.75l18.02-77.11h-11.62l-18.02 77.11h11.62zM42.8 26.25c-8.36 0-15.8 3.88-20.65 9.93L30.48.52H18.86L.84 77.62h11.62l4.5-19.24c2.6 11.89 13.18 20.79 25.84 20.79 14.61 0 26.46-11.85 26.46-26.46 0-14.61-11.85-26.46-26.46-26.46z"
      ></path>
    </svg>
  );
}

export function BejamasMark({
  className,
  style,
}: {
  className?: string;
  style?: React.CSSProperties;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="213"
      height="242"
      fill="none"
      viewBox="0 0 213 242"
      className={cn("h-auto fill-current w-[3rem]", className)}
      style={style}
    >
      <path
        fill="currentColor"
        d="M171.208 241.189l41.58-177.91h-26.81l-41.58 177.91h26.81zM97.298 60.08c-19.28 0-36.46 8.95-47.65 22.91L68.878.72h-26.81L.488 178.622h26.81l10.38-44.4c6 27.42 30.41 47.96 59.63 47.96 33.72 0 61.05-27.33 61.05-61.05s-27.33-61.05-61.05-61.05h-.01z"
      ></path>
    </svg>
  );
}

export function BejamasIcon({
  className,
  style,
}: {
  className?: string;
  style?: React.CSSProperties;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="242"
      height="242"
      fill="none"
      viewBox="0 0 242 242"
      className={cn("h-auto fill-current w-[3rem]", className)}
      style={style}
    >
      <path fill="#131314" d="M0 0h242v242H0z"></path>
      <g fill="#fff" clipPath="url(#a)">
        <path d="M160.534 195 186 86.25h-16.416L144.118 195zM115.278 84.287c-11.805 0-22.325 5.471-29.177 14.005L97.876 48H81.46L56 156.751h16.416l6.356-27.142c3.674 16.762 18.62 29.318 36.512 29.318 20.647 0 37.382-16.707 37.382-37.32s-16.735-37.32-37.382-37.32z"></path>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M56 48h130v147H56z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
