import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "../lib/utils";

const buttonVariants = cva(
  "inline-flex cursor-pointer relative items-center transition-all ease justify-center whitespace-nowrap rounded-full text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "bg-white text-foreground hover:bg-foreground hover:text-white min-w-20",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input bg-background hover:bg-muted hover:border-muted-foreground/30 hover:text-accent-foreground",
        secondary: "bg-muted hover:bg-muted border",
        ghost:
          "border border-transparent hover:text-accent-foreground hover:bg-muted",
        link: "text-primary underline-offset-4 hover:underline",
        accent: "bg-accent transition-all hover:bg-accent/80 disabled:bg-muted",
        bordered: "bg-background border hover:bg-border",
        transparent: "bg-transparent backdrop-blur-md border border-[#E0DFDF]",
      },
      size: {
        default: "h-10 px-4 py-2 text-base",
        xs: "h-8 rounded-md px-2",
        sm: "h-9 rounded-full px-3",
        lg: "h-12 rounded-full text-lg px-6",
        xl: "h-14 md:h-20 rounded-full text-xl px-6 md:px-12",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
