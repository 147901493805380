import { LazyVideo } from "./lazy-video";

export default function AlproBgVideo() {
  return (
    <LazyVideo
      src="/videos/alpro-testimonial.mp4"
      className="hidden object-cover absolute top-0 left-0 w-auto h-full sm:block"
      autoPlay
      controls={false}
    />
  );
}
