import React from "react";
import { AnimatePresence, m as motion } from "framer-motion";
import { Link, useLocation } from "@remix-run/react";
import { cn } from "@bejamas/ui/utils";
import { Button } from "@bejamas/ui/button";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@bejamas/ui/navigation-menu";

import { FastLink } from "../../components/faster-link";

export function useMobileNav() {
  const closeMobileNav = React.useCallback(() => {
    React.startTransition(() => {
      const checkbox = document.getElementById(
        "nav-toggle",
      ) as HTMLInputElement;
      if (checkbox) {
        checkbox.checked = false;
      }
    });
  }, []);

  return { closeMobileNav };
}

function MobileNav() {
  const { closeMobileNav } = useMobileNav();

  return (
    <>
      <input
        type="checkbox"
        id="nav-toggle"
        className="absolute opacity-0 nav-toggle"
      />
      <label
        htmlFor="nav-toggle"
        className="flex fixed right-5 top-6 z-40 flex-wrap justify-center items-center px-3 mt-px w-10 h-10 bg-white rounded-full md:hidden hamburger-menu"
      >
        <div>
          <div className="hamburger-line w-4 h-0.5 bg-foreground rounded transition-all ease-in-out duration-500" />
          <div className="hamburger-line w-4 h-0.5 mt-0.5 bg-foreground rounded transition-all ease-in-out duration-500" />
          <div className="hamburger-line w-4 h-0.5 mt-0.5 bg-foreground rounded transition-all ease-in-out duration-500" />
        </div>
      </label>
      <div className="fixed top-0 left-0 invisible z-30 pt-40 pb-8 w-full text-2xl opacity-0 transition-all duration-500 ease-in-out min-h-dvh nav-links bg-background">
        <Link
          to="/work"
          className="block px-5 py-3 w-full"
          onClick={closeMobileNav}
        >
          Work
        </Link>
        <Link
          to="/services"
          className="block px-5 py-3 w-full"
          onClick={closeMobileNav}
        >
          Services
        </Link>
        <Link
          to="/blog"
          className="block px-5 py-3 w-full"
          onClick={closeMobileNav}
        >
          Blog
        </Link>
        <Link
          to="/about"
          className="block px-5 py-3 w-full"
          onClick={closeMobileNav}
        >
          About
        </Link>
        <Link
          to="/get-in-touch"
          className="block px-5 py-3 w-full"
          onClick={closeMobileNav}
        >
          Get in touch
        </Link>

        <Button
          variant="accent"
          size="xl"
          asChild
          className="absolute right-5 bottom-5 left-5"
        >
          <Link to="/get-in-touch" onClick={closeMobileNav}>
            Contact
          </Link>
        </Button>
      </div>
      {/* <label
        htmlFor="nav-toggle"
        className="fixed inset-0 invisible z-20 opacity-0 backdrop-blur-md transition-opacity duration-150 ease-out menu-backdrop bg-gray-100/70"
      /> */}
    </>
  );
}

function NavItem({
  to,
  title,
  cursorPosition,
  isHovering,
  children,
  className,
  content,
  ...props
}: {
  to: string;
  title: string;
  cursorPosition: { x: number; y: number };
  isHovering: boolean;
  children?: React.ReactNode;
  className?: string;
  content?: React.ReactNode;
}) {
  const LinkOrButton = !content ? FastLink : NavigationMenuTrigger;

  return (
    <LinkOrButton
      to={to}
      className={cn(
        "group px-5 py-2.5 text-lg data-[state=open]:text-current relative z-20 flex items-center",
        className,
      )}
      prefetch="viewport"
      {...props}
    >
      {title}
      {children}
      {content ? (
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
          className="relative flex-1 ml-2 w-5 h-5"
          variants={{
            open: { rotate: 90 },
            hover: { rotate: 45 },
            closed: { rotate: 0 },
          }}
          // transition={{
          //   type: "spring",
          //   duration: 0.4,
          //   bounce: 0.2,
          // }}
          role="graphics-symbol"
        >
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M2.625 12a9.375 9.375 0 1118.75 0 9.375 9.375 0 01-18.75 0zM12 1.375C6.132 1.375 1.375 6.132 1.375 12S6.132 22.625 12 22.625 22.625 17.868 22.625 12 17.868 1.375 12 1.375zm-1.5 7a.625.625 0 100 1.25h2.991l-4.933 4.933a.625.625 0 00.884.884l4.933-4.933v2.99a.625.625 0 101.25 0V9A.625.625 0 0015 8.376h-4.5z"
            clipRule="evenodd"
          />
        </motion.svg>
      ) : null}
    </LinkOrButton>
  );
}

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <FastLink
          ref={ref}
          className={cn(
            "block p-3 space-y-1 leading-tight no-underline rounded-lg transition-colors outline-none select-none hover:bg-background hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className,
          )}
          prefetch="viewport"
          {...props}
        >
          <div className="text-lg leading-[1.15] text-balance">{title}</div>
          <p className="text-sm line-clamp-2 text-muted-foreground">
            {children}
          </p>
        </FastLink>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = "ListItem";

const items = [
  {
    title: "Work",
    to: "/work",
  },
  {
    title: "Services",
    to: "/services",
  },
  {
    title: "About",
    to: "/about",
  },
  {
    title: "Learn",
    to: null,
    content: ({
      onMouseEnter,
      onMouseLeave,
    }: { onMouseEnter: () => void; onMouseLeave: () => void }) => (
      <NavigationMenuContent
        className="pt-4 w-full z-60"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <ul className="grid bg-white rounded-xl gap-1 p-2 md:w-[200px] lg:w-[240px] lg:grid-cols-[1fr]">
          <ListItem to="/blog" title="Blog">
            Business content, our thoughts, and more.
          </ListItem>
          <ListItem to="/hub" title="Guide to Building Better Websites">
            Everything for Marketers and Devs to build a modern website.
          </ListItem>
          <ListItem to="/compare" title="Compare Centre">
            Find the right stack for your project.
          </ListItem>
        </ul>
      </NavigationMenuContent>
    ),
  },
  // {
  //   title: "Start project",
  //   to: "/get-in-touch",
  // },
];

const MotionNavigationMenuItem = motion.create(NavigationMenuItem);

export function Nav() {
  const [isHovering, setIsHovering] = React.useState(false);
  const [hoveringItem, setHoveringItem] = React.useState(null);
  const [submenuIsHovering, setSubmenuIsHovering] = React.useState(false);
  const [hasBeenClicked, setHasBeenClicked] = React.useState(false);
  const ref = React.useRef<HTMLElement>(null);
  const audioRef = React.useRef<HTMLAudioElement | null>(null);

  const playClickSound = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    }
  };

  const handleMouseDown = () => {
    // flushSync(() => {
    //   playClickSound();
    // });
    // playClickSound();

    setHasBeenClicked(true);

    setTimeout(() => {
      setHasBeenClicked(false);
    }, 100);
  };

  return (
    <>
      <MobileNav />
      <NavigationMenu
        className="flex z-auto space-x-0 text-xl text-black"
        ref={ref}
        delayDuration={0}
      >
        <motion.div layout className="hidden lg:block">
          <NavigationMenuList
            className="space-x-0 fixed right-6 top-8 z-40 justify-start w-5/12 lg:min-w-[600px] text-[var(--blend-color)]"
            style={{
              mixBlendMode: "var(--blend)",
            }}
          >
            <div
              className="flex"
              onMouseEnter={() => {
                setIsHovering(true);
              }}
              onMouseLeave={() => {
                setIsHovering(false);
              }}
            >
              {items.map((item) => {
                return (
                  <MotionNavigationMenuItem
                    key={item.title}
                    to={item.to}
                    title={item.title}
                    className="relative"
                    initial="closed"
                    whileHover={!submenuIsHovering ? "hover" : "open"}
                    animate={submenuIsHovering ? "open" : "closed"}
                  >
                    <NavItem
                      to={item.to}
                      title={item.title}
                      content={item.content}
                      isHovering={isHovering}
                      onMouseEnter={() => setHoveringItem(item)}
                      onMouseLeave={() => setHoveringItem(item)}
                      onMouseDown={handleMouseDown}
                      // className="right-[32.0625rem]"
                    >
                      <AnimatePresence>
                        {hoveringItem?.title === item.title && isHovering ? (
                          <motion.div
                            layoutId="hovering-item"
                            initial={{
                              filter: "blur(2px)",
                            }}
                            animate={hasBeenClicked ? "clicked" : "visible"}
                            exit={{
                              opacity: 0,
                              filter: "blur(8px)",
                            }}
                            transition={{
                              type: "spring",
                              bounce: 0,
                              duration: 0.4,
                            }}
                            variants={{
                              visible: {
                                opacity: 1,
                                filter: "blur(0px)",
                                scale: 1,
                              },
                              clicked: {
                                scale: 0.95,
                                opacity: 1,
                                filter: "blur(4px)",
                              },
                              exit: {
                                scale: 1,
                                opacity: 0,
                                filter: "blur(8px)",
                              },
                            }}
                            className={cn(
                              "absolute inset-0 z-50 rounded-full bg-[var(--blend-color-bg)]",
                            )}
                            // style={{
                            //   backgroundColor: "rgba(255,255,255,0.05)",
                            // }}
                          />
                        ) : null}
                      </AnimatePresence>
                    </NavItem>
                    {item?.content
                      ? item.content({
                          onMouseEnter: () => setSubmenuIsHovering(true),
                          onMouseLeave: () => setSubmenuIsHovering(false),
                        })
                      : null}
                  </MotionNavigationMenuItem>
                );
              })}
            </div>
          </NavigationMenuList>
        </motion.div>
      </NavigationMenu>
      <div className="fixed top-6 right-[4.75rem] z-29 md:top-8 md:right-8">
        <Button variant="accent" asChild className="md:hidden">
          <FastLink
            to="/get-in-touch"
            prefetch="render"
            onMouseDown={() => {
              playClickSound();
            }}
          >
            Contact
          </FastLink>
        </Button>
      </div>
      <div className="fixed top-6 right-[4.75rem] z-50 md:top-8 md:right-8">
        <Button variant="accent" size="lg" asChild className="hidden md:flex">
          <FastLink
            to="/get-in-touch"
            prefetch="render"
            onMouseDown={() => {
              playClickSound();
            }}
          >
            Start project
          </FastLink>
        </Button>
      </div>
      <audio ref={audioRef} src="/click2.wav" />
    </>
  );
}
