import type { HeadersFunction, MetaFunction } from "@remix-run/node";
import { Outlet } from "@remix-run/react";
import { SiteFooter } from "./_marketing-2/footer";
import { SiteHeader } from "./_marketing/site-header";
import { LazyMotion, domMax } from "framer-motion";

export const headers: HeadersFunction = () => ({
  "Cache-Control": "max-age=300, s-maxage=300, stale-while-revalidate=604800",
});

export const meta: MetaFunction = ({ location }) => {
  const ogImageUrl =
    "https://cdn.sanity.io/images/34ent8ly/production/33618c6b0735064168409823d99ef499ed82be2c-2400x1260.png";

  const pathname = location.pathname;

  return [
    { title: "Bejamas: Building better websites" },
    {
      name: "description",
      content:
        "We combine modern tech stack with exceptional design and performance to help your business thrive online.",
    },
    {
      property: "description",
      content:
        "We combine modern tech stack with exceptional design and performance to help your business thrive online.",
    },
    {
      property: "og:description",
      content:
        "We combine modern tech stack with exceptional design and performance to help your business thrive online.",
    },
    {
      property: "twitter:description",
      content:
        "We combine modern tech stack with exceptional design and performance to help your business thrive online.",
    },
    { property: "og:type", content: "website" },
    { property: "og:url", content: `https://bejamas.com${pathname}` },
    {
      property: "twitter:title",
      content: "Bejamas: Building better websites",
    },
    { property: "og:title", content: "Bejamas: Building better websites" },
    { property: "og:image", content: ogImageUrl },
    {
      property: "twitter:image",
      content: `${ogImageUrl}`,
    },
  ];
};

export default function Route() {
  return (
    <>
      <LazyMotion features={domMax} strict>
        <SiteHeader />
        <div className="relative z-10 bg-background">
          <Outlet />
        </div>
        <SiteFooter />
      </LazyMotion>
      <div id="logo-context-default" />
      <div id="service-modal" />
    </>
  );
}
