import React from "react";
import { AnimatePresence, m as motion } from "framer-motion";

const logos = [
  {
    name: "Danone",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/99a6831433c4191b15d9ac0b458b62aae9a1c4fa-1000x315.svg"
        className="relative top-1 w-auto h-7"
        alt="Danone Logo"
      />
    ),
  },
  {
    name: "Deliverect",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/986da3378aab01798df4cbe603fccb35440e2e9c-134x25.svg"
        alt="Deliverect Logo"
        className="w-auto h-6"
      />
    ),
  },
  {
    name: "Costa Coffee",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/f6b153b98c5cc423c4e3c8f98119b575a78cfe72-680x168.svg"
        alt="Costa Coffee logo"
        className="w-auto h-6"
      />
    ),
  },
  {
    name: "Newfront",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/0c54692367f57c8c8d932421375eff1ee2db51a5-140x20.svg"
        alt="Newfront logo"
        className="w-auto h-5"
      />
    ),
  },
  {
    name: "Routable",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/bb165d7e4c287c73ca534c1848af30698353ffdd-95x16.svg"
        alt="Routable logo"
        className="w-auto h-5"
        loading="lazy"
        width={95}
        height={16}
        decoding="async"
      />
    ),
  },
  {
    name: "Backlinko",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/f9b595eef5151fbe9df8e735fd09d0dcf3438604-188x25.svg"
        alt="Backlinko logo"
        className="w-auto h-5"
        loading="lazy"
        width={188}
        height={25}
        decoding="async"
      />
    ),
  },
  {
    name: "VanRaam",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/0f5f362db371df71d115e895c0abfc916afcccb8-192x60.svg"
        alt="VanRaam logo"
        className="w-auto h-9"
        loading="lazy"
        width={192}
        height={60}
        decoding="async"
      />
    ),
  },
  // {
  //   name: "SKF",
  //   logo: <Skf className="w-auto h-5" />,
  // },
  {
    name: "GKN Automotive",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/092837ae88776e419d7d40052051549dd46f8d94-150x51.svg"
        alt="GKN Automotive logo"
        className="w-auto h-7"
        loading="lazy"
        width={150}
        height={51}
        decoding="async"
      />
    ),
  },
  {
    name: "Transcend",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/2abb9013ae4df7c8b1674aeb34e71cee218b1f4b-128x30.svg"
        alt="Transcend logo"
        className="w-auto h-8"
        loading="lazy"
        width={128}
        height={30}
        decoding="async"
      />
    ),
  },
  {
    name: "SumUp",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/3b9c208646ed78666730a8c631b469709fee829e-214x63.svg"
        alt="SumUp logo"
        className="w-auto h-8"
        loading="lazy"
        width={214}
        height={63}
        decoding="async"
      />
    ),
  },
  {
    name: "DrSmile",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/c4e68190b62ab684b36ee820ba1c32dddf0d0e71-120x16.svg"
        alt="DrSmile logo"
        className="w-auto h-4"
        loading="lazy"
        width={120}
        height={16}
        decoding="async"
      />
    ),
  },
  {
    name: "Mambu",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/4a39b66ee2bcbf881ea1842bbf799849e992add7-768x158.svg"
        alt="Mambu logo"
        className="w-auto h-6"
        loading="lazy"
        width={768}
        height={158}
        decoding="async"
      />
    ),
  },
  {
    name: "Metalshub",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/43b3484cc8df06f8c2eacdf1669b873a5e6a3e4f-1807x399.svg"
        alt="Metalshub logo"
        className="w-auto h-6"
        loading="lazy"
        width={1807}
        height={399}
        decoding="async"
      />
    ),
  },
  {
    name: "FinanzChef24",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/4b888e8ebc905fea5854cc5b9a5d6fb628d08467-258x97.svg"
        alt="Finanz Chef 24 logo"
        className="w-auto h-6"
        loading="lazy"
        width={258}
        height={97}
        decoding="async"
      />
    ),
  },
  {
    name: "SpotPet Insurance",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/74e1437f73c1c2ee46b3e9b849177a91a1c8ec8a-116x53.svg"
        alt="SpotPet logo"
        className="w-auto h-7"
        loading="lazy"
        width={116}
        height={53}
        decoding="async"
      />
    ),
  },
  {
    name: "Bennetts",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/eef1a8dad4fec19eb75d97876c6ddf6c6b32262c-176x36.svg"
        alt="Bennetts logo"
        className="w-auto h-6"
        loading="lazy"
        width={176}
        height={36}
        decoding="async"
      />
    ),
  },
  {
    name: "Charm Industrial",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/84dc08989818cdb7c6719b2f17ad8fc69a889a65-97x21.svg"
        alt="Charm logo"
        className="w-auto h-6"
        loading="lazy"
        width={97}
        height={21}
        decoding="async"
      />
    ),
  },
  {
    name: "Climate Central",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/d7e095a19260ce1344a5ee2fbeb3f450d00e5c1a-340x27.svg"
        alt="Climate Central logo"
        className="w-auto h-3"
        loading="lazy"
        width={340}
        height={27}
        decoding="async"
      />
    ),
  },
  {
    name: "O1Labs",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/6dab5e924adb803c95629400f7a39422382112fc-64x42.svg"
        alt="o1labs logo"
        className="w-auto h-8"
        loading="lazy"
        width={64}
        height={42}
        decoding="async"
      />
    ),
  },
  {
    name: "Camino Financial",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/460e82c3359e564be016dd51894c9e1cd6060203-554x159.svg"
        alt="Camino Financial logo"
        className="w-auto h-7"
        loading="lazy"
        width={554}
        height={159}
        decoding="async"
      />
    ),
  },
  {
    name: "BSCS Learning",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/7d3d2b7fa62e44e25912f4abd3a95d666c52dc66-742x208.svg"
        alt="BSCS logo"
        className="w-auto h-6"
        loading="lazy"
        width={742}
        height={208}
        decoding="async"
      />
    ),
  },
  {
    name: "Eurac Research",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/e508a50f3dc02070f2437704d7825b5d1b455f77-179x20.svg"
        alt="eurac research logo"
        className="w-auto h-4"
        loading="lazy"
        width={179}
        height={20}
        decoding="async"
      />
    ),
  },
  {
    name: "Rocky Mountain PBS",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/ca82a34456bee78a81303d1cce63a826f5e85f5b-49x11.svg"
        alt="Rocky Mountain logo"
        className="w-auto h-7"
        loading="lazy"
        width={49}
        height={11}
        decoding="async"
      />
    ),
  },
  {
    name: "State of Maryland",
    logo: (
      <img
        src="https://cdn.sanity.io/images/34ent8ly/production/7c6eff49c58e4e204ee330631879ad14cdc789f9-266x180.svg"
        alt="State of Maryland logo"
        className="w-auto h-10 grayscale"
        loading="lazy"
        width={266}
        height={180}
        decoding="async"
      />
    ),
  },
];

function MobileLogos() {
  return (
    <ul className="grid flex-wrap grid-cols-2 items-center pb-4 md:hidden">
      <li className="flex relative justify-center items-center py-6 h-full">
        <img
          src="https://cdn.sanity.io/images/34ent8ly/production/99a6831433c4191b15d9ac0b458b62aae9a1c4fa-1000x315.svg"
          className="w-auto h-6"
          alt="Danone Logo"
          width={1000}
          height={315}
          decoding="async"
        />
        <div className="absolute top-0 -right-px w-px h-full bg-gradient-to-t to-transparent from-border" />
        {/* <div className="absolute bottom-0 -right-px w-px h-1/2 bg-gradient-to-b to-transparent from-border" /> */}

        <div className="absolute bottom-0 w-full h-px bg-gradient-to-l to-transparent from-border" />

        {/* <Cross className="-bottom-2 -right-2 -mr-px z-[2]" /> */}
      </li>
      <li className="flex relative justify-center items-center py-6 h-full">
        <img
          src="https://cdn.sanity.io/images/34ent8ly/production/986da3378aab01798df4cbe603fccb35440e2e9c-134x25.svg"
          alt="Deliverect Logo"
          className="w-auto h-5"
          width={134}
          height={25}
          decoding="async"
        />
        {/* <div className="absolute top-0 -right-px w-px h-full bg-gradient-to-t to-transparent from-border" /> */}
        {/* <div className="absolute bottom-0 -right-px w-px h-1/2 bg-gradient-to-b to-transparent from-border" /> */}
        <div className="absolute bottom-0 w-full h-px bg-border" />

        {/* <Cross className="-bottom-2 -right-2 -mr-px z-[2]" /> */}
      </li>
      <li className="flex relative justify-center items-center py-6 h-full">
        <img
          src="https://cdn.sanity.io/images/34ent8ly/production/f6b153b98c5cc423c4e3c8f98119b575a78cfe72-680x168.svg"
          alt="Costa Coffee logo"
          className="w-auto h-5"
          width={680}
          height={168}
          decoding="async"
        />
        <div className="absolute top-0 -right-px w-px h-full bg-gradient-to-b to-transparent from-border" />
        {/* <div className="absolute bottom-0 -right-px w-px h-1/2 bg-gradient-to-b to-transparent from-border" /> */}
        {/* <div className="absolute bottom-0 w-full h-px bg-border" /> */}

        {/* <Cross className="-bottom-2 -right-2 -mr-px z-[2]" /> */}
      </li>
      <li className="flex relative justify-center items-center py-6 h-full">
        <img
          src="https://cdn.sanity.io/images/34ent8ly/production/0c54692367f57c8c8d932421375eff1ee2db51a5-140x20.svg"
          alt="Newfront logo"
          className="w-auto h-4"
          width={140}
          height={20}
          decoding="async"
        />
        {/* <div className="absolute top-0 -right-px w-px h-full bg-gradient-to-t to-transparent from-border" /> */}
        {/* <div className="absolute bottom-0 -right-px w-px h-1/2 bg-gradient-to-b to-transparent from-border" /> */}
        {/* <div className="absolute bottom-0 w-full h-px bg-border" /> */}
        {/* <Cross className="-bottom-2 -right-2 -mr-px z-[2]" /> */}
      </li>
    </ul>
  );
}

export function Customers({ clients }) {
  const [renderRow, setRenderRow] = React.useState(0);
  const numberOfItemsPerRow = 3;
  const lastRow = Math.ceil(clients.length / numberOfItemsPerRow) - 1;

  const logosToRender = React.useMemo(() => {
    const start = renderRow * numberOfItemsPerRow;
    const end = start + numberOfItemsPerRow;
    return clients.slice(start, end).map((client, i) => ({
      ...client,
      key: `${client.name}-${i}`, // Ensure unique keys without cycle
    }));
  }, [renderRow, clients]);

  React.useEffect(() => {
    let animationFrameId: number;
    const interval = setInterval(() => {
      animationFrameId = requestAnimationFrame(() => {
        setRenderRow((prev) => (prev === lastRow ? 0 : prev + 1));
      });
    }, 5000);

    return () => {
      clearInterval(interval);
      cancelAnimationFrame(animationFrameId);
    };
  }, [lastRow]);

  return (
    <div className="md:pb-8">
      {/* <MobileLogos /> */}
      <ul className="grid relative z-10 flex-wrap grid-cols-2 items-center pb-4 h-24 lg:grid-cols-4">
        <AnimatePresence mode="popLayout">
          {logosToRender.map((client, i) => (
            <motion.li
              key={client.id}
              className="flex absolute justify-center items-center py-6 w-1/5 h-full"
              style={{
                left: `${i * (100 / numberOfItemsPerRow)}%`,
              }}
              variants={{
                hidden: {
                  opacity: 0,
                  y: 12,
                  filter: "blur(5px)",
                },
                visible: {
                  opacity: 1,
                  y: 0,
                  filter: "blur(0px)",
                },
                exit: {
                  opacity: 0,
                  y: (i + 1 * 0.75) * -14 * 0.75,
                  filter: "blur(5px)",
                },
              }}
              transition={{
                stiffness: 70,
                damping: 10 + (i + 1) * 0.5,
                delay: i * 0.12,
                type: "spring",
                filter: {
                  bounce: 0,
                },
              }}
              animate="visible"
              initial="hidden"
              exit="exit"
            >
              <img src={client.logo.asset.url} alt={client.name} />
            </motion.li>
          ))}
        </AnimatePresence>
      </ul>
    </div>
  );
}
