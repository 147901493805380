import { useMemo } from "react";

export type GridRowConfig = readonly (readonly string[])[];

interface GridPosition {
  rowIndex: number;
  columnIndex: number;
  className: string;
}

export function calculateGridPositions(
  totalItems: number,
  gridRows: GridRowConfig,
): GridPosition[] {
  const positions: GridPosition[] = [];
  const totalPositionsInGrid = gridRows.reduce(
    (sum, row) => sum + row.length,
    0,
  );

  for (let i = 0; i < totalItems; i++) {
    // Calculate which iteration of the grid we're on
    const gridIteration = Math.floor(i / totalPositionsInGrid);
    // Calculate the position within the current grid iteration
    const positionInGrid = i % totalPositionsInGrid;

    let currentCount = 0;
    let rowIndex = 0;
    let columnIndex = 0;

    // Find the correct row and column within the grid
    for (let j = 0; j < gridRows.length; j++) {
      if (
        positionInGrid >= currentCount &&
        positionInGrid < currentCount + gridRows[j].length
      ) {
        rowIndex = j;
        columnIndex = positionInGrid - currentCount;
        break;
      }
      currentCount += gridRows[j].length;
    }

    positions.push({
      rowIndex: rowIndex + gridIteration * gridRows.length,
      columnIndex,
      className: gridRows[rowIndex][columnIndex],
    });
  }

  return positions;
}

export function useGridPositions(totalItems: number, gridRows: GridRowConfig) {
  return useMemo(
    () => calculateGridPositions(totalItems, gridRows),
    [totalItems, gridRows],
  );
}
