import {
  Carousel,
  CarouselContent,
  CarouselItem,
  // CarouselNext,
  // CarouselPrevious,
} from "@bejamas/ui/carousel";
import { Image } from "@unpic/react";

export function Features() {
  return (
    <Carousel
      opts={{
        breakpoints: {
          "(min-width: 768px)": {
            watchDrag: false,
          },
        },
      }}
    >
      <CarouselContent className="gap-1 mx-5 lg:mx-8">
        <CarouselItem className="md:basis-1/3 p-5 lg:p-8 bg-white rounded-3xl pb-5 lg:h-[26rem] flex items-end lg:items-start lg:pt-54">
          <div className="relative">
            <div className="absolute right-0 -top-20 lg:-top-12 lg:-right-3">
              <div className="absolute top-0 left-0 w-16 h-16 rounded-full bg-accent" />
              <Image
                src="https://cdn.sanity.io/images/34ent8ly/production/e4f581a6ffea7535497182104724b2414855d333-240x240.png"
                alt="Consult"
                width={120}
                height={120}
                className="relative z-10"
              />
            </div>
            <h4>Strategic planning</h4>
            <p className="mt-10 text-2xl text-balance">
              We analyze your goals to craft the perfect blend of design and
              technical solutions.
            </p>
          </div>
        </CarouselItem>
        <CarouselItem className="md:basis-1/3 p-5 lg:p-8 bg-white rounded-3xl pb-5 lg:h-[37rem] flex items-end lg:items-start lg:pt-54">
          <div className="relative">
            <div className="absolute right-0 -top-20 lg:-top-12 lg:-right-3">
              <div className="absolute top-0 left-0 w-16 h-16 rounded-full bg-accent" />
              <Image
                src="https://cdn.sanity.io/images/34ent8ly/production/73c6c2f6cc552e69acba70ce47d34201a349cfa5-240x240.png"
                alt="Consult"
                width={120}
                height={120}
                className="relative z-10"
              />
            </div>
            <h4>User-focused design</h4>
            <p className="mt-10 text-2xl text-balance">
              We design intuitive experiences that turn your business goals into
              reality.
            </p>
          </div>
        </CarouselItem>
        <CarouselItem className="md:basis-1/3 bg-white rounded-3xl p-5 lg:p-8 lg:h-[26rem] mt-auto pt-48 items-end lg:items-start lg:pt-54">
          <div className="relative w-full">
            <div className="absolute right-0 -top-20 lg:-top-12 lg:-right-3">
              <div className="absolute top-0 left-0 w-16 h-16 rounded-full bg-accent" />
              <Image
                src="https://cdn.sanity.io/images/34ent8ly/production/ebe56ec0043f65f42c3cbadd3331b0462c9ba1fe-240x240.png"
                alt="Consult"
                width={120}
                height={120}
                className="relative z-10"
              />
            </div>
            <h4>Performance-driven development</h4>
            <p className="mt-10 text-2xl text-balance">
              We build fast, scalable websites that deliver measurable results.
            </p>
          </div>
        </CarouselItem>
      </CarouselContent>
    </Carousel>
  );
}
