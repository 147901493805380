// import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import React from "react";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
  isRouteErrorResponse,
  useRouteLoaderData,
} from "@remix-run/react";

import type { LoaderFunctionArgs } from "@remix-run/node";
// import "@bejamas/ui/styles.css";
// import "./globals.css";
// import uiStyles from "@bejamas/ui/styles.css";
import { SiteHeader } from "./routes/_marketing/site-header";
import { SiteFooter } from "./routes/_marketing-2/footer";

import { Toaster } from "@bejamas/ui/sonner";
import { TooltipProvider } from "@bejamas/ui/tooltip";
import { removeTrailingSlashes, isProductionHost } from "~/lib/http.server";
import { SpeedInsights } from "@vercel/speed-insights/remix";
import { BejamasMark } from "@bejamas/ui/logo";
import Clarity from "@microsoft/clarity";

import "./globals.css";

import sanityRichTextStyles from "./sanity-rich-text-C40RoeXk.css?url";

const CLARITY_ID = "mfh699p391";

declare global {
  interface Window {
    reb2b: any[] & {
      invoked?: boolean;
      methods?: string[];
      factory?: (method: string) => Function;
      SNIPPET_VERSION?: string;
      load?: (key: string) => void;
    };
  }
}

export function links() {
  const preloadedFonts = ["PPNeueMontreal-Variable-opt.woff2"];

  return [
    { rel: "icon", href: "/favicon.ico" },
    ...preloadedFonts.map((font) => ({
      rel: "preload",
      as: "font",
      type: "font/woff2",
      href: `/fonts/${font}`,
      crossOrigin: "",
    })),
    // {
    //   rel: "stylesheet",
    //   href: uiStyles,
    // },
    {
      rel: "stylesheet",
      href: sanityRichTextStyles,
    },
  ];
}

// export const loader: LoaderFunction = (args) => rootAuthLoader(args);

export async function loader({ request }: LoaderFunctionArgs) {
  removeTrailingSlashes(request);

  const isDevHost = !isProductionHost(request);
  const url = new URL(request.url);

  return {
    host: url.host,
    isProductionHost: !isDevHost,
    noIndex: isDevHost,
    // css: appStyles,
  };
}

function RB2BTrackingScript() {
  React.useEffect(() => {
    // Initialize tracking only on client-side
    const initializeTracking = () => {
      window.reb2b = window.reb2b || [];

      if (window.reb2b.invoked) return;
      window.reb2b.invoked = true;
      window.reb2b.methods = ["identify", "collect"];

      window.reb2b.factory = (method) => {
        return (...args) => {
          args.unshift(method);
          window.reb2b.push(args);
          return window.reb2b;
        };
      };

      for (let i = 0; i < window.reb2b.methods.length; i++) {
        const key = window.reb2b.methods[i] as string;
        window.reb2b[key] = window.reb2b.factory(key);
      }

      window.reb2b.SNIPPET_VERSION = "1.0.1";

      // Load the main tracking script
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src =
        "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/R6G5YHZ37365/R6G5YHZ37365.js.gz";

      const firstScript = document.getElementsByTagName("script")[0];
      if (firstScript?.parentNode) {
        firstScript.parentNode.insertBefore(script, firstScript);
      }
    };

    initializeTracking();
  }, []);

  return null;
}

export function Layout({ children }: { children: React.ReactNode }) {
  const { isProductionHost, noIndex } =
    useRouteLoaderData<typeof loader>("root");

  React.useEffect(() => {
    if (isProductionHost) {
      Clarity.init(CLARITY_ID);
    }
  }, [isProductionHost]);

  return (
    <html lang="en" suppressHydrationWarning className="bg-background">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {noIndex && <meta name="robots" content="noindex" />}
        <meta name="twitter:site" content="@bejamas_io" />
        <meta name="apple-mobile-web-app-title" content="Bejamas" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta property="og:site_name" content="Bejamas" />
        <meta property="og:locale" content="en_US" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" content="@bejamas_io" />
        <meta property="twitter:creator" content="@bejamas_io" />
        <meta httpEquiv="Content-Language" content="en" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        {/* <style
          dangerouslySetInnerHTML={{
            __html: css,
          }}
        /> */}
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/icon/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/icon/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/icon/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/icon/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/icon/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/icon/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/icon/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/icon/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/icon/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/icon/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/icon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/icon/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/icon/favicon-16x16.png"
        />
        <link rel="manifest" href="/icon/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content="/icon/ms-icon-144x144.png"
        />
        <meta name="theme-color" content="oklch(96.44% 0.0013 286.38)" />
        {/* <Partytown debug={!isProductionHost} forward={["dataLayer.push"]} /> */}

        {isProductionHost ? (
          <>
            <script
              src="https://plausible.io/js/script.outbound-links.tagged-events.js"
              data-domain="bejamas.com"
              defer
            />
            <script
              dangerouslySetInnerHTML={{
                __html:
                  "window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }",
              }}
            />
            <script
              src="https://analytics.ahrefs.com/analytics.js"
              data-key="SA2kCjcMRG5zs603rxsrdA"
              async
            />
          </>
        ) : null}

        <Meta />
        <Links />
      </head>
      <body className="font-sans antialiased">
        <TooltipProvider>{children}</TooltipProvider>

        <Toaster />
        <Scripts />
        <ScrollRestoration />
        <SpeedInsights dsn="8W8Y9YA8qBPhU6y7CHsOl1DG9gs" />
        {isProductionHost ? <RB2BTrackingScript /> : null}
      </body>
    </html>
  );
}

function App() {
  return <Outlet />;
}

// export default withSentry(App);
export default App;

// export default ClerkApp(App);

export function ErrorBoundary() {
  const error = useRouteError();

  // captureRemixErrorBoundaryError(error);

  return (
    <html lang="en">
      <head>
        <title>Oops!</title>
        <Meta />
        <Links />
      </head>
      <body>
        <SiteHeader />
        <section className="mt-64 mb-40 mx-auto flex p-10 max-w-4xl rounded-3xl bg-[#E9E9E9]">
          <div className="flex absolute flex-none justify-center items-center mr-5 w-10 h-10 rounded-full bg-foreground">
            <BejamasMark className="w-4 h-4 text-white" />
          </div>
          <div className="container max-w-2xl text-balance">
            <p className="mt-1 text-2xl">Oops!</p>

            <p className="mt-6 text-muted-foreground">
              {isRouteErrorResponse(error)
                ? `${error.status}`
                : error instanceof Error
                  ? error.message
                  : "Unknown Error"}
            </p>
          </div>
        </section>
        <SiteFooter />

        <Scripts />
      </body>
    </html>
  );
}
