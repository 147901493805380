import React from "react";
import { m as motion } from "framer-motion";

export const VercelStyle3DTextEffect = ({
  text,
}: { text: React.ReactNode }) => {
  const [mousePosition, setMousePosition] = React.useState({ x: 0, y: 0 });

  if (!text) {
    return <div>No text provided</div>;
  }

  const handleMouseMove = (event: React.MouseEvent) => {
    const { currentTarget, clientX, clientY } = event;
    const { left, top } = currentTarget.getBoundingClientRect();

    setMousePosition({
      x: clientX - left,
      y: clientY - top,
    });
  };

  return (
    <motion.div
      className="relative"
      whileHover="hover"
      onMouseMove={handleMouseMove}
    >
      <span>{text}</span>
      <motion.div
        className="pointer-events-none absolute top-0 h-full left-0 w-64 backdrop-blur-[1.5px] bg-[rgba(189,252,100,0.0125)] icon-blur-mask"
        style={{
          y: 0,
          x: mousePosition.x - 128, // center the blur effect (half of width)
        }}
        initial={{
          opacity: 0,
        }}
        variants={{
          hover: {
            opacity: 1,
          },
        }}
        // transition={{
        //   type: "spring",
        //   damping: 30,
        //   stiffness: 300,
        //   mass: 0.5,
        // }}
      />
    </motion.div>
  );
};
