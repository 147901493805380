import React from "react";
import { Link as RemixLink, useNavigate } from "@remix-run/react";
import type { LinkProps } from "@remix-run/react";
import { useEffect, forwardRef, useRef, useState } from "react";

type PrefetchImage = {
  srcset: string;
  sizes: string;
  src: string;
  alt: string;
  loading: string;
};

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function prefetchImages(href: string) {
  if (!href.startsWith("/")) {
    return [];
  }
  const url = new URL(href, window.location.href);
  const imageResponse = await fetch(
    `/api/prefetch-images?pathname=${url.pathname}`,
    {
      priority: "low",
    },
  );

  // only throw in dev
  if (!imageResponse.ok && process.env.NODE_ENV === "development") {
    throw new Error("Failed to prefetch images");
  }

  const { images } = (await imageResponse.json()) as {
    images: PrefetchImage[];
  };
  return images;
}

const seen = new Set<string>();
const seenData = new Set<string>();

export const FastLink = React.memo(
  forwardRef<HTMLAnchorElement, LinkProps>(({ children, ...props }, ref) => {
    const [images, setImages] = useState<PrefetchImage[]>([]);
    const linkRef = useRef<HTMLAnchorElement>(null);
    const navigate = useNavigate();
    const prefetchTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
      if (props.prefetch === "none") {
        return;
      }

      const linkElement = linkRef.current;
      if (!linkElement) return;

      const observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0];
          if (entry.isIntersecting) {
            prefetchTimeoutRef.current = setTimeout(async () => {
              // Remix doesn't have a direct prefetch API, but the Link component
              // will handle prefetching automatically when rendered
              await sleep(0);
              void prefetchImages(String(props.to)).then((images) => {
                // Lazy load images that come into view
                for (const image of images) {
                  if (seen.has(image.src)) continue;

                  const img = new Image();
                  img.fetchPriority = "low";
                  img.loading = "lazy";

                  if (!image.src.endsWith(".svg")) {
                    img.sizes = image.sizes;
                    img.srcset = image.srcset;
                  }

                  // console.log("🐌 lazy loading image:", { src: image.src });

                  img.onload = () => {
                    seen.add(img.src); // Only add to seen after successful load
                    // console.log("🐌 lazy loaded image:", { src: img.src });
                    img.remove();
                  };
                  img.onerror = () => {
                    console.error("error loading lazy image", { src: img.src });
                  };

                  img.src = image.src; // Set src last to start loading
                }
                setImages(images);
              }, console.error);
              observer.unobserve(entry.target);
            }, 300);
          } else if (prefetchTimeoutRef.current) {
            clearTimeout(prefetchTimeoutRef.current);
            prefetchTimeoutRef.current = null;
          }
        },
        { rootMargin: "0px", threshold: 0.1 },
      );

      observer.observe(linkElement);

      return () => {
        observer.disconnect();
        if (prefetchTimeoutRef.current) {
          clearTimeout(prefetchTimeoutRef.current);
        }
      };
    }, [props.to, props.prefetch]);

    const handleMouseOver = () => {
      // for Safari we wan't to prefetch data as link prefetch doesn't work there
      // if (navigator.vendor.includes("Apple")) {
      if (!seenData.has(String(props.to))) {
        fetch(String(`${props.to}.data`), {
          priority: "high",
          method: "GET",
          headers: {
            Purpose: "prefetch",
            Accept:
              "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7",
          },
        });
        seenData.add(String(props.to));
      }

      // Eager load on hover
      for (const image of images) {
        if (image.loading === "lazy" || seen.has(image.src)) {
          continue;
        }
        const img = new Image();

        // Set properties for eager loading
        img.decoding = "async";
        img.fetchPriority = "high"; // Increased priority for hover
        img.loading = "eager"; // Eager load on hover
        img.style.display = "none";

        if (!image.src.endsWith(".svg")) {
          img.sizes = image.sizes;
          img.srcset = image.srcset;
        }

        seen.add(image.src);
        img.src = image.src;
        img.alt = image.alt;
        // console.log("🚀 eagerly prefetching image:", { image });

        img
          .decode()
          .then(() => {
            // console.log("✨ eagerly loaded image:", { src: img.src });
            img.remove();
          })
          .catch((encodingError) => {
            seen.delete(image.src);
            console.error("error decoding eager image", {
              image,
              encodingError,
            });
          });
      }
    };

    const handleMouseDown = (e: React.MouseEvent<HTMLAnchorElement>) => {
      const url = new URL(String(props.to), window.location.href);
      if (
        url.origin === window.location.origin &&
        e.button === 0 &&
        !e.altKey &&
        !e.ctrlKey &&
        !e.metaKey &&
        !e.shiftKey
      ) {
        e.preventDefault();
        navigate(String(props.to));
      }

      if (props.onMouseDown) {
        props.onMouseDown(e);
      }
    };

    return (
      <RemixLink
        ref={(node) => {
          // This handles both the forwarded ref and the internal ref
          if (typeof ref === "function") ref(node);
          else if (ref) ref.current = node;
          linkRef.current = node;
        }}
        prefetch="viewport"
        onMouseOver={handleMouseOver}
        {...props}
        onClick={(e) => {
          if (props.onClick) {
            props.onClick(e);
          }
          e.preventDefault();
        }}
        onMouseDown={handleMouseDown}
      >
        {children}
      </RemixLink>
    );
  }),
);

FastLink.displayName = "FastLink";
