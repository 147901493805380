import * as React from "react";
import { m as motion, stagger } from "framer-motion";

// 1. Memoize the animation configuration
const baseAnimationConfig = {
  type: "spring",
  duration: 1.66,
  bounce: 0,
} as const;

const baseInitialState = {
  opacity: 0,
  y: 20,
  rotateX: 0,
  filter: "blur(12px)",
} as const;

// 2. Create a memoized word component
const AnimatedWord = React.memo(function AnimatedWord({
  text,
  wordDelay,
  initial,
}: {
  text: React.ReactNode;
  wordDelay: number;
  initial?: typeof baseInitialState;
}) {
  return (
    <motion.span
      className="inline-block"
      initial={{ ...baseInitialState, ...initial }}
      animate={{ opacity: 1, y: 0, rotateX: 0, filter: "blur(0px)" }}
      transition={{ ...baseAnimationConfig, delay: wordDelay }}
    >
      {text}
    </motion.span>
  );
});

export const AnimatedWords = React.memo(function AnimatedWords({
  baseDelay = 0,
  words,
  initial,
}: {
  baseDelay?: number;
  words: Array<{ text: React.ReactNode; delay?: number | "auto" }>;
  initial?: typeof baseInitialState;
}) {
  // 3. Memoize staggerDelay calculation
  const staggerDelay = React.useMemo(
    () => stagger(0.075, { ease: "easeIn" }),
    [],
  );

  // 4. Memoize word delays calculation
  const wordDelays = React.useMemo(
    () =>
      words.map(({ delay }, index) =>
        delay === "auto" || delay === undefined
          ? baseDelay + staggerDelay(index, words.length)
          : typeof delay === "number"
            ? delay
            : baseDelay + staggerDelay(index, words.length),
      ),
    [words, baseDelay, staggerDelay],
  );

  const id = React.useId();

  return (
    <>
      {words.map(({ text }, index) => {
        if (text === "break") {
          return <br key={`${id}-break-${index}`} />;
        }

        return (
          <React.Fragment key={`${id}-${index}`}>
            <AnimatedWord
              text={text}
              wordDelay={wordDelays[index]}
              initial={initial}
            />{" "}
          </React.Fragment>
        );
      })}
    </>
  );
});
