import React from "react";
import { m as motion } from "framer-motion";
import { Image } from "@unpic/react";
import { cn } from "@bejamas/ui/utils";
import { FastLink } from "~/components/faster-link";
import { useDebounceCallback, useResizeObserver } from "usehooks-ts";
import { blurhashToCssGradientString } from "@unpic/placeholder";

// Static configurations to avoid recreating objects
const initialAnimation = {
  opacity: 0,
  // y: 4,
  // scale: 0.99,
  // filter: "blur(12px)",
} as const;

const animateConfig = {
  opacity: 1,
  y: 0,
  scale: 1,
  filter: "blur(0px)",
} as const;

const exitAnimation = {
  opacity: 0,
  y: -24,
  scale: 0.95,
  // filter: "blur(24px)",
  transition: {
    duration: 0.44,
    type: "spring",
    bounce: 0,
  },
} as const;

const transitionConfig = {
  duration: 0.66,
  type: "spring",
  bounce: 0,
} as const;

interface Metric {
  _key: string;
  prefix?: string;
  value: string | number;
  suffix?: string;
  label: string;
}

interface ProjectImage {
  asset: {
    url: string;
  };
}

interface Project {
  slug: string;
  id: string;
  title: string;
  client: string;
  year: string;

  metrics?: Metric[];
  mainImage?: ProjectImage;
  mainImageTwoThirds?: ProjectImage;
  mainImageOneThird?: ProjectImage;
}

interface ProjectCardProps {
  project: Project;
  heading: string;
  index: number;
  className: string;
  variant?: "compact" | "default";
}

// Helper function to select the appropriate image based on column width
function selectProjectImage(
  project: Project,
  className?: string,
): ProjectImage | undefined {
  // Add null check for className
  if (!className) {
    return (
      project.mainImage ||
      project.mainImageTwoThirds ||
      project.mainImageOneThird
    );
  }

  if (className.includes("col-span-12")) {
    return (
      project.mainImage ||
      project.mainImageTwoThirds ||
      project.mainImageOneThird
    );
  }

  if (className.includes("col-span-8") || className.includes("col-span-7")) {
    return (
      project.mainImageTwoThirds ||
      project.mainImage ||
      project.mainImageOneThird
    );
  }

  return (
    project.mainImageOneThird || project.mainImageTwoThirds || project.mainImage
  );
}
const ArrowIcon = React.memo(() => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="View project"
    className="transition-all duration-500 delay-100 scale-90 group-hover:scale-100 group-hover:opacity-100 group-hover:transition-all group-hover:blur-0"
  >
    <path
      d="M11.959 11.3337L15.2923 8.00033L11.959 4.66699"
      stroke="#131314"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2925 8L1 8"
      stroke="#131314"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));

export const ProjectCard = React.memo(function ProjectCard({
  project,
  index,
  className,
  variant = "default",
}: ProjectCardProps) {
  const heading = [project.client?.name, project.year]
    .filter(Boolean)
    .join(", ");
  const projectImage = React.useMemo(
    () => selectProjectImage(project, className),
    [project, className],
  );

  const elementRef = React.useRef<HTMLDivElement>(null);
  const { height = 0 } = useResizeObserver({
    ref: elementRef,
    box: "border-box",
  });

  const titleRef = React.useRef<HTMLParagraphElement>(null);
  const { height: titleHeight = 0 } = useResizeObserver({
    ref: titleRef,
    box: "border-box",
  });

  return (
    <FastLink
      to={`/work/${project.slug}`}
      className={cn("block rounded-3xl", className)}
      key={project.id + index}
      // ref={elementRef}
    >
      <motion.div
        whileHover="hover"
        className={cn("relative w-full group", {
          "md:h-[var(--height)]": variant === "compact",
        })}
        initial={initialAnimation}
        animate={animateConfig}
        exit={exitAnimation}
        transition={transitionConfig}
        style={{
          "--height": `${height}px`,
          "--title-height": `${titleHeight}px`,
        }}
      >
        <div
          className={cn(
            "z-10 h-full duration-[500ms] transition-all ease-[cubic-bezier(.075,.82,.165,1)]",
            "relative bg-neutral-200 overflow-clip rounded-3xl flex flex-col justify-between",
            {
              "md:h-[var(--height)] md:group-hover:h-[calc(var(--height)-var(--title-height)-44px)]":
                variant === "compact",
            },
          )}
        >
          <p className="absolute top-5 left-5 z-10 text-2xl text-white/70 mix-blend-plus-lighter">
            {heading}
          </p>

          <div className="flex absolute right-5 bottom-5 left-5 z-10 flex-wrap gap-y-1 mt-auto">
            {project.metrics?.map((metric) => (
              <div
                key={metric._key}
                className="bg-white px-4 md:px-6 py-2 text-sm md:text-base rounded-[2.5rem]"
              >
                <span className="font-medium">
                  {metric.prefix}
                  {metric.value}
                  {metric.suffix}
                </span>{" "}
                {metric.label}
              </div>
            ))}
          </div>

          <div ref={elementRef}>
            {projectImage && (
              <Image
                layout="fullWidth"
                src={projectImage?.asset.url}
                alt={project.title}
                className={cn(
                  "object-cover hidden lg:block object-top top-0 right-0 left-0 w-full transition-transform duration-1000 ease-[cubic-bezier(.075,.82,.165,1)] -z-0",
                  {
                    "md:group-hover:-translate-y-4": variant === "compact",
                  },
                )}
                // background={blurhashToCssGradientString(
                //   projectImage.asset.metadata.blurHash,
                // )}
                loading={index < 4 ? "eager" : "lazy"}
                decoding={index < 2 ? "sync" : "async"}
              />
            )}

            <Image
              layout="fullWidth"
              src={project?.mainImageOneThird?.asset.url}
              alt={project.title}
              className={cn(
                "object-cover lg:hidden object-top top-0 right-0 left-0 w-full transition-transform duration-1000 ease-[cubic-bezier(.075,.82,.165,1)] -z-0",
                {
                  "md:group-hover:-translate-y-4": variant === "compact",
                },
              )}
              // background={blurhashToCssGradientString(
              //   projectImage.asset.metadata.blurHash,
              // )}
              loading={index < 2 ? "eager" : "lazy"}
              decoding={index < 2 ? "sync" : "async"}
            />
          </div>
        </div>

        <div
          ref={titleRef}
          className={cn(
            "flex bottom-0 z-0  justify-between items-center px-5 w-full",
            {
              "md:absolute md:mb-10": variant === "compact",
            },
          )}
        >
          <p
            className={cn(
              "pt-5 text-lg md:text-2xl md:max-w-2/3 text-balance group-hover:scale-100 transition-all duration-500 delay-100 group-hover:opacity-100 group-hover:blur-0",
              {
                "md:opacity-0 md:scale-[0.995]": variant === "compact",
              },
            )}
          >
            {project.title}
          </p>
          <div className="hidden px-6 py-3 h-10 rounded-3xl opacity-0 transition-all duration-500 translate-y-2 md:block group-hover:opacity-100 group-hover:transition-all bg-accent group-hover:blur-0">
            <ArrowIcon />
          </div>
        </div>
      </motion.div>
    </FastLink>
  );
});
