import { BejamasWordmark } from "@bejamas/ui/logo";
import { Customers } from "./_marketing/customers-3";
import { AnimatedWords } from "./_marketing-2/animated-words";
import { CanvasAnimation } from "./_marketing/canvas-animation";
import { m as motion } from "framer-motion";
import { useLoaderData } from "@remix-run/react";
import { InteractiveCircles } from "./_marketing._index/interactive-circles";
import { Image } from "@unpic/react";
import { getHomepage } from "~/lib/sanity";
import { VercelStyle3DTextEffect } from "./_marketing-2/3d-text-effect";
import { ProjectCard } from "./_marketing-2/project-card";
import { useGridPositions } from "./_marketing-2/projects-grid";
import { FastLink } from "~/components/faster-link";
import type { MetaFunction } from "@remix-run/react";
import { FeaturedTestimonial } from "./_marketing._index/featured-testimonial";
import { Features } from "./_marketing._index/features";

export function loader() {
  return getHomepage();
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  const { ogImage } = data;
  // Retrieve these values from the loader
  return [
    { title: "Bejamas: Building better websites" },
    {
      name: "description",
      content:
        "We combine modern technology with exceptional design to create websites that drive real business impact.",
    },
    {
      property: "og:description",
      content:
        "We combine modern technology with exceptional design to create websites that drive real business impact.",
    },
    {
      property: "twitter:description",
      content:
        "We combine modern technology with exceptional design to create websites that drive real business impact.",
    },
    { property: "og:type", content: "website" },
    { property: "og:url", content: "https://bejamas.com" },
    {
      property: "twitter:title",
      content: "Bejamas: Building better websites",
    },
    { property: "og:title", content: "Bejamas: Building better websites" },
    { property: "og:image", content: ogImage.asset.url },
    {
      property: "twitter:image",
      content: `${ogImage.asset.url}&medium=twitter`,
    },
  ];
};

const HOME_GRID_ROWS = [
  ["md:col-span-7", "md:col-span-5"],
  ["md:col-span-5", "md:col-span-7"],
  ["md:col-span-7", "md:col-span-5"],
  ["md:col-span-12"],
  ["md:col-span-7 md:col-start-2", "md:col-span-5"],
  ["md:col-span-8", "md:col-span-4"],
  ["md:col-span-7", "md:col-span-5"],
  ["col-span-12"],
] as const;

function Projects() {
  const { featuredProjects } = useLoaderData<typeof loader>();
  const positions = useGridPositions(featuredProjects.length, HOME_GRID_ROWS);

  return (
    <div className="grid gap-1 gap-y-10 px-6 pt-16 lg:px-8 md:grid-cols-12">
      {featuredProjects.map((project, i) => (
        <ProjectCard
          key={project.id}
          className={positions[i].className}
          project={project}
          index={i}
          variant="compact"
        />
      ))}
    </div>
  );
}

function ProjectsMore({ length }: { length: number }) {
  return (
    <motion.div whileHover="hover" className="px-6 mb-10 md:mb-24 lg:px-8">
      <FastLink
        to="/work"
        className="flex justify-between items-center mt-20 text-3xl lg:justify-start lg:mt-24 lg:text-6xl group"
      >
        <VercelStyle3DTextEffect
          text={
            <>
              Explore more{" "}
              <span className="hidden md:inline-block">({length})</span>
            </>
          }
        />
        <div className="inline-block px-10 py-3 ml-12 rounded-3xl transition duration-300 ease-in-out bg-accent md:group-hover:translate-x-5">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="graphics-symbol"
          >
            <path
              d="M11.959 11.3337L15.2923 8.00033L11.959 4.66699"
              stroke="#131314"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.2925 8L1 8"
              stroke="#131314"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </FastLink>
    </motion.div>
  );
}

export default function Route() {
  const { totalProjects, featuredClients } = useLoaderData<typeof loader>();

  return (
    <>
      {/* bg-card-foreground for dark */}
      {/* bg-background for light */}
      <div className="relative pt-40 bg-background min-h-svh">
        {/* Light */}
        <motion.div
          initial={{ filter: "blur(30px)" }}
          animate={{ filter: "blur(0px)" }}
          transition={{ type: "spring", bounce: 0, duration: 2 }}
          className="hidden lg:block absolute ml-[2%] w-[96%] top-16 md:top-6"
        >
          <BejamasWordmark className="z-20 w-full h-auto pointer-events-none text-muted" />
          {/* <BejamasWordmark className="w-[96%] ml-[2%] h-auto text-black mix-blend-overlay absolute top-6 z-20 pointer-events-none" /> */}
        </motion.div>
        <div className="flex absolute bottom-0 z-20 justify-between px-5 w-full lg:px-8 md:mt-0">
          <div className="ml-auto mt-40 lg:mt-0 lg:w-5/12 text-2xl lg:pb-[7vh]">
            <h1 className="mb-8 text-5xl md:mb-12 md:text-7xl text-balance">
              <AnimatedWords
                words={[
                  {
                    text: "Building",
                  },
                  {
                    text: "better",
                  },
                  {
                    text: "websites",
                  },
                ]}
              />
            </h1>

            <p className="max-w-xl text-xl md:text-2xl text-balance">
              We combine modern technology with exceptional design to create
              websites that drive real business impact.
            </p>
            <p className="mt-14 text-base text-muted-foreground">
              Companies we've helped succeed
            </p>
            <div className="max-w-lg">
              <Customers clients={featuredClients} />
            </div>
          </div>
        </div>

        {/* Dark  */}
        {/* <motion.div
          initial={{ filter: "blur(30px)" }}
          animate={{ filter: "blur(0px)" }}
          transition={{ type: "spring", bounce: 0, duration: 2 }}
        >
          <BejamasWordmark className="w-[96%] ml-[2%] h-auto text-[#BEBEBE] absolute top-6 z-20 pointer-events-none" />
        </motion.div> */}

        <div className="absolute top-0 z-10 -mt-12 w-full h-screen mix-blend-multiply pointer-events-none">
          <CanvasAnimation mode="light" />
        </div>
      </div>

      <section>
        <InteractiveCircles />
        <Features />
      </section>
      <section>
        <Projects />
        <ProjectsMore length={totalProjects} />
      </section>
      <FeaturedTestimonial />
    </>
  );
}
