import ReactPlayer from "react-player/lazy";

export default function VideoAlpro({
  playFullVideo,
}: {
  playFullVideo: boolean;
}) {
  return (
    <ReactPlayer
      url="https://youtu.be/MdjTMR4PsK8"
      className="absolute top-0 left-0 z-10 w-full overflow-clip rounded-md aspect-video"
      width="100%"
      height="auto"
      playing={playFullVideo}
      controls
    />
  );
}
