import { useEffect, useRef } from "react";

type LazyVideoProps = {
  src: string;
  controls?: boolean;
  autoPlay?: boolean;
  type?: string;
  className?: string;
};

export const LazyVideo = ({
  src,
  controls = true,
  autoPlay = false,
  type = "video/mp4",
  className,
}: LazyVideoProps) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if ("IntersectionObserver" in window && videoElement) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const sources = videoElement.querySelectorAll("source");
              sources.forEach((source) => {
                source.src = source.dataset.src;
              });
              videoElement.load();
              videoElement.classList.remove("lazy");
              observer.unobserve(videoElement);
            }
          });
        },
        {
          threshold: 0.25, // Configure as needed, 0.25 means 25% of the element should be visible
        },
      );

      observer.observe(videoElement);

      return () => {
        observer.disconnect();
      };
    }
  }, [src]);

  return (
    <video
      ref={videoRef}
      controls={controls}
      autoPlay={autoPlay}
      className={className}
      playsInline
      muted
      loop
    >
      <source data-src={src} type={type} />
    </video>
  );
};
