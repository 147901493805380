import React from "react";
import { BejamasWordmark } from "@bejamas/ui/logo";
import {
  AnimatePresence,
  useScroll,
  useTransform,
  m as motion,
} from "framer-motion";
import { FastLink } from "~/components/faster-link";
import { Button } from "@bejamas/ui/button";
import { Image } from "@unpic/react";
import { useLocation } from "@remix-run/react";

const knowledgeHubGroups = [
  [
    {
      type: "heading",
      name: "Learn",
    },
    {
      name: "Knowledge",
      slug: "/hub",
      iconName: "view",
    },
    {
      name: "Compare Center",
      slug: "/compare",
      iconName: "sword-fighting",
    },
  ],
  [
    {
      type: "heading",
      name: "For manager",
    },
    {
      name: "Headless CMS",
      slug: "/hub/headless-cms",
      iconName: "document",
    },
  ],
  [
    {
      type: "heading",
      name: "For developer",
    },
    {
      name: "Web Frameworks",
      slug: "/hub/web-frameworks",
      iconName: "paint-roller",
    },
    {
      name: "Static Site Generators",
      slug: "/hub/static-site-generators",
      iconName: "hammer",
    },
    {
      name: "Serverless Databases",
      slug: "/hub/serverless-database",
      iconName: "data-management",
    },
    {
      name: "Hosting",
      slug: "/hub/hosting",
      iconName: "cloud",
    },
  ],
  [
    {
      type: "heading",
      name: "Adopt modern stack",
    },
    {
      name: "Practical guides",
      slug: "/hub/guides",
      iconName: "cloud",
    },
    {
      name: "Tutorials",
      slug: "/hub/tutorials",
      iconName: "cloud",
    },
    {
      name: "Case studies",
      slug: "/hub/case-studies",
      iconName: "server",
    },
  ],
];

function KnowledgeHubLinks() {
  return knowledgeHubGroups.map((group, i) => (
    <ul key={`group--${i}`} className="text-lg md:text-xl">
      {group.map((item) => {
        if (item?.type === "heading") {
          return (
            <React.Fragment key={`group--${i}--${item.name}`}>
              <p className="text-xs font-normal lg:text-sm text-muted-foreground">
                ({i + 1}.0)
              </p>

              <p className="mt-1 mb-10 text-sm font-normal lg:text-base">
                {item.name}
              </p>
            </React.Fragment>
          );
        }

        return (
          <li key={`group--${i}--${item.slug}`} className="mt-3">
            <FastLink
              to={item.slug}
              prefetch="intent"
              className="inline underline-animation"
            >
              {item.name}
            </FastLink>
          </li>
        );
      })}
    </ul>
  ));
}

const buttonCopy = {
  bottom: "Go to top",
  scrolling: {
    0: "Scrolling",
    1: "knew it",
    2: "again?",
    3: "come on",
    4: "ok",
    5: "got it",
    6: "hello",
  },
  success: "Done",
  hidden: "Done",
} as const;

function ScrollToTop() {
  const [howManyTimes, setHowManyTimes] = React.useState(-1);
  const [state, setState] = React.useState<keyof typeof buttonCopy>("bottom");
  const lastClickRef = React.useRef<number>(0);

  const handleBackToTop = () => {
    // window.scrollTo(0, 0);
    const now = Date.now();
    const timeSinceLastClick = now - lastClickRef.current;

    // Only increment if click happened within 30 seconds of the last click
    if (timeSinceLastClick < 15000 || lastClickRef.current === 0) {
      setHowManyTimes((prev) => prev + 1);
    } else {
      // Reset counter if more than 30 seconds passed
      setHowManyTimes(0);
    }

    lastClickRef.current = now;

    setState("scrolling");
    window.scrollTo({ top: 0, behavior: "smooth" });

    setTimeout(() => {
      setState("success");
    }, 600);

    setTimeout(() => {
      setState("hidden");
    }, 1200);

    setTimeout(() => {
      setState("bottom");
    }, 1800);
  };

  return (
    <div
      className="relative bottom-0 -mt-48 h-48 md:sticky"
      style={{
        zIndex: state !== "bottom" ? 100 : 1,
      }}
    >
      <motion.button
        onClick={handleBackToTop}
        className="flex absolute left-5 bottom-16 md:left-12"
        animate={state}
        transition={{ type: "spring", duration: 0.3, bounce: 0 }}
        variants={{
          scrolling: {
            position: "fixed",
            zIndex: 100,
            bottom: "4rem",
          },
          success: {
            position: "fixed",
            zIndex: 100,
            bottom: "4rem",
          },
          hidden: {
            position: "fixed",
            zIndex: 100,
            bottom: "4rem",
            opacity: 0,
            filter: "blur(10px)",
            y: 120,
          },
        }}
        whileHover="hover"
      >
        <div className="rounded-3xl mr-1 flex items-center px-4 bg-white h-[46px] z-[101]  w-[111px] relative justify-center">
          <AnimatePresence>
            <motion.span
              transition={{ type: "spring", duration: 0.3, bounce: 0 }}
              initial={{ opacity: 0, y: -20, filter: "blur(4px)" }}
              animate={{ opacity: 1, y: 0, filter: "blur(0px)" }}
              exit={{ opacity: 0, y: 20, filter: "blur(4px)" }}
              key={state}
              className="absolute"
            >
              {state === "scrolling"
                ? buttonCopy.scrolling[
                    howManyTimes as keyof typeof buttonCopy.scrolling
                  ] || "Scrolling"
                : buttonCopy[state]}
            </motion.span>
          </AnimatePresence>
        </div>
        <motion.div
          transition={{
            duration: 0.3,
            type: "spring",
            bounce: 0,
          }}
          variants={{
            hover: {
              x: 20,
            },
          }}
        >
          <motion.svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            animate={state}
            transition={{
              duration: 0.3,
              type: "spring",
              bounce: 0,
            }}
            variants={{
              scrolling: {
                scale: 80,
                // filter: "blur(10px)",
              },
              success: {
                scale: 1,
                filter: "blur(0px)",
              },
              bottom: {
                scale: 1,
                filter: "blur(0px)",
              },
            }}
            role="graphics-symbol"
          >
            <motion.rect
              width="46"
              height="46"
              rx="23"
              fill="var(--color-accent)"
            />
          </motion.svg>
        </motion.div>
        <motion.svg
          width="67"
          height="67"
          viewBox="0 0 67 67"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="-ml-[46px]"
          transition={{
            duration: 0.3,
            type: "spring",
            bounce: 0,
          }}
          variants={{
            hover: {
              x: -46,
            },
          }}
          role="graphics-symbol"
        >
          <path
            d="M51.7247 66.4004L67.0043 1.00195H57.1547L41.875 66.4004H51.7247Z"
            fill="var(--color-accent)"
          />
        </motion.svg>
      </motion.button>
    </div>
  );
}

function KnowledgeHub() {
  return (
    <div className="bottom-0 pt-16 lg:-mt-12 md:pt-32 md:sticky bg-background">
      <div className="px-6 lg:px-12">
        <h2 className="mb-10 text-2xl font-medium lg:text-3xl lg:mb-20 text-balance">
          knowledge hub for managers,{" "}
          <span className="text-muted-foreground">devs and nerds</span>
        </h2>
        <div className="grid grid-cols-2 gap-x-5 gap-y-10 md:grid-cols-4">
          <KnowledgeHubLinks />
        </div>
        <div className="h-48" />
      </div>
    </div>
  );
}

const MotionButton = motion.create(Button);

function Cta() {
  const { pathname } = useLocation();

  const [isHovered, setIsHovered] = React.useState(false);
  const [mousePosition, setMousePosition] = React.useState({ x: 0, y: 0 });

  const handleMouseMove = (event: React.MouseEvent) => {
    const { currentTarget, clientX, clientY } = event;
    const { left, top } = currentTarget.getBoundingClientRect();

    setMousePosition({
      x: clientX - left,
      y: clientY - top,
    });
  };

  const isContact = pathname.includes("/get-in-touch");
  const headingWidth = isContact ? 633 : 385;
  const buttonWidth = isContact ? 163 : 141;

  return (
    <section className="px-8 text-white md:px-12 bg-card-foreground">
      <div className="text-center w-full border-b py-10 lg:py-16 border-b-[#212122]">
        <p className="mb-6 text-muted-foreground">
          {isContact
            ? "Thanks for following all those CTAs"
            : "Need a better website?"}
        </p>
        <div className="flex justify-center w-full">
          <motion.div className="inline-block relative">
            <h2 className="mb-10 text-5xl lg:mb-16 md:text-7xl">
              {isContact ? "Let's make it happen" : "Let's build it"}
            </h2>
            <motion.div
              className="pointer-events-none absolute top-0 h-full left-0 w-64 backdrop-blur-[1.5px] bg-[oklch(0.14_0_0_/_0.01)] icon-blur-mask"
              style={{
                y: 0,
                x: (mousePosition.x / buttonWidth) * headingWidth - 128, // center the blur effect (half of width)
              }}
              initial={{
                opacity: 0,
              }}
              variants={{
                hover: {
                  opacity: 1,
                },
              }}
              animate={isHovered ? "hover" : undefined}

              // transition={{
              //   type: "spring",
              //   damping: 30,
              //   stiffness: 300,
              //   mass: 0.5,
              // }}
            />
          </motion.div>
        </div>

        <MotionButton
          variant="accent"
          size="lg"
          asChild
          className="text-black group"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onMouseMove={handleMouseMove}
          whileHover="hover"
        >
          {!isContact ? (
            <FastLink to="/get-in-touch">Get in touch</FastLink>
          ) : (
            <FastLink to="#form">
              To the form
              <motion.svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
                className="relative transition-transform ease-in-out group-hover:rotate-[-45deg] flex-1 ml-2 w-5 h-5"
                initial="open"
                role="graphics-symbol"
              >
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M2.625 12a9.375 9.375 0 1118.75 0 9.375 9.375 0 01-18.75 0zM12 1.375C6.132 1.375 1.375 6.132 1.375 12S6.132 22.625 12 22.625 22.625 17.868 22.625 12 17.868 1.375 12 1.375zm-1.5 7a.625.625 0 100 1.25h2.991l-4.933 4.933a.625.625 0 00.884.884l4.933-4.933v2.99a.625.625 0 101.25 0V9A.625.625 0 0015 8.376h-4.5z"
                  clipRule="evenodd"
                />
              </motion.svg>
            </FastLink>
          )}
        </MotionButton>
      </div>
    </section>
  );
}

export function SiteFooter() {
  const [finalScale, setScale] = React.useState(1);

  React.useEffect(() => {
    const calculateScale = () => {
      // Only apply scaling on desktop screens (>= 1024px)
      const isDesktop = window.matchMedia("(min-width: 1024px)").matches;
      if (!isDesktop) {
        setScale(1);
        return;
      }

      const screenWidth = window.innerWidth;
      const targetWidth = screenWidth - 48; // 48px margin on each side
      const newScale = targetWidth / screenWidth;
      setScale(newScale);
    };

    calculateScale();
    window.addEventListener("resize", calculateScale);

    return () => window.removeEventListener("resize", calculateScale);
  }, []);

  const targetRef = React.useRef<HTMLDivElement | null>(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end end"],
  });

  const borderRadius = useTransform(scrollYProgress, [0.7, 1], ["0", "30px"]);
  const scale = useTransform(scrollYProgress, [0.7, 1], ["1", finalScale]);

  return (
    <>
      <div ref={targetRef} className="relative bg-background">
        <motion.footer
          className="overflow-hidden relative z-10 w-full text-white bg-card-foreground"
          style={{ borderRadius, scale }}
        >
          <Cta />
          <div className="grid mt-5 lg:mt-8 lg:grid-cols-12">
            <div className="col-span-6 px-6 lg:px-8">
              <p className="max-w-3xl text-xl text-balance">
                Keep the Web weird.
                {/* <br /> */}
                {/* Every pixel, every millisecond counts. */}
              </p>
            </div>
            <div className="grid grid-cols-12 col-span-6 px-6 mt-10 lg:pt-2 lg:mt-0 lg:px-0">
              <div className="col-span-6">
                <p className="text-xs font-normal lg:text-sm text-muted-foreground">
                  (1.0)
                </p>
                <p className="mt-1 text-sm font-normal lg:text-base">Pages</p>
                <ul className="grid gap-3 mt-10 text-lg lg:text-xl md:grid-cols-2">
                  <li>
                    <FastLink
                      to="/services"
                      className="inline underline-animation"
                    >
                      Services
                    </FastLink>
                  </li>
                  <li>
                    <FastLink to="/work" className="inline underline-animation">
                      Work
                    </FastLink>
                  </li>
                  <li>
                    <FastLink
                      to="/about"
                      className="inline underline-animation"
                    >
                      About us
                    </FastLink>
                  </li>
                  <li>
                    <FastLink
                      to="/careers"
                      className="inline underline-animation"
                    >
                      Careers
                    </FastLink>
                  </li>
                  <li>
                    <FastLink
                      to="/get-in-touch"
                      className="inline underline-animation"
                    >
                      Get in touch
                    </FastLink>
                  </li>
                </ul>
              </div>
              <div className="col-span-6">
                <p className="text-xs font-normal lg:text-sm text-muted-foreground">
                  (2.0)
                </p>
                <p className="mt-1 text-sm font-normal lg:text-base">Connect</p>
                <ul className="grid gap-3 mt-10 text-lg lg:text-xl md:grid-cols-2">
                  <li>
                    <a
                      href="https://github.com/bejamas"
                      className="inline underline-animation"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Github
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.linkedin.com/company/bejamas"
                      className="inline underline-animation"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      LinkedIn
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://dribbble.com/bejamas"
                      className="inline underline-animation"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Dribbble
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://x.com/bejamas_io"
                      className="inline underline-animation"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      X
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-span-12 mt-10 lg:mt-20">
                <p className="text-xs font-normal lg:text-sm text-muted-foreground">
                  (3.0)
                </p>
                <p className="mt-1 text-sm font-normal lg:text-base">
                  Recognition
                </p>
                <ul className="grid grid-cols-2 gap-3 gap-y-5 mt-10 text-lg lg:text-xl sm:grid-cols-3 text-balance">
                  <li>
                    1000
                    <p className="text-sm text-muted-foreground max-w-52">
                      Europe's Fastest Growing Companies 2023
                    </p>
                  </li>
                  <li>
                    Fast 50
                    <p className="text-sm text-muted-foreground max-w-52">
                      Technology Fast 50 CE Laureate 2022
                    </p>
                  </li>
                  <li>
                    Jamstack
                    <p className="text-sm text-muted-foreground max-w-52">
                      Agency of the Year 2021
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="relative pt-20 mt-0 overflow-clip lg:pt-52 lg:-mt-52">
            <Image
              src="https://cdn.sanity.io/images/34ent8ly/production/81a930e257530a936844889af993053d010fccc3-1920x1080.png"
              className="absolute -left-[600px] top-0 rotate-[77deg] z-30"
              alt=""
              width={1716}
              height={965.7}
            />
            <div className="relative">
              <BejamasWordmark className="w-[96%] relative ml-[2%] h-auto text-[#F3F3F4] -bottom-[3px]  z-20 pointer-events-none" />
              {/* <BejamasWordmark className="w-[96%] ml-[2%] h-auto text-white bottom-0 left-px top-px opacity-20 absolute z-10 pointer-events-none" /> */}
            </div>
          </div>
          <div className="flex bottom-0 z-40 justify-between px-6 pb-6 mt-5 w-full lg:mt-20 lg:px-8 lg:absolute text-muted-foreground">
            <div className="flex">
              <FastLink to="/legal/privacy-policy">Privacy Policy</FastLink>
            </div>
            <p>© {new Date().getFullYear()}</p>
          </div>
        </motion.footer>
        <KnowledgeHub />
      </div>

      <ScrollToTop />
    </>
  );
}
